<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        {{
          (types === "diller" && "Дилер") ||
            (types === "kindergarten" && "Детский сад") ||
            (types === "default" && "Компания") ||
            (types === "service" && "Сервис") ||
            "Загрузка"
        }}
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle></FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required"
              label="Название"
              v-model="name"
              validate-name="название"
            ></ValidationInputField>
            <ValidationInputField
              rules="required"
              label="Полное название"
              v-model="full_org_name"
              validate-name="полное название"
            >
            </ValidationInputField>
            <ValidationAutocompleteField
              :search-function="citySearch"
              rules="required"
              label="Город"
              v-model="city"
              :return-object="false"
              validate-name="город"
            />
            <ValidationAutocompleteField
              :search-function="dillerSearch"
              v-if="
                getCurrentRole === 70 &&
                  !(types === 'diller') &&
                  !(types === 'oppen')
              "
              rules=""
              label="Дилер"
              v-model="diller"
              validate-name="дилер"
            />
            <ValidationSelectField
              v-model="ownership"
              :items="organizationType"
              rules="required"
              label="Тип организации"
              validate-name="тип организации"
            ></ValidationSelectField>
            <ValidationInputField
              v-model="address"
              rules="required"
              label="Юр. адрес"
              validate-name="юр. адрес"
            ></ValidationInputField>
            <ValidationInputField
              rules=""
              v-model="actual_address"
              label="Фактический адрес"
            />
            <ValidationInputField
              v-model="phone_number"
              mask="+7 (###) ###-##-##"
              rules="required|all_phone"
              label="Телефон"
              validate-name="телефон"
            ></ValidationInputField>
            <ValidationInputField
              rules="required|email"
              v-model="email"
              label="E-mail"
              validate-name="E-mail"
            ></ValidationInputField>
            <ValidationInputField v-model="director" label="Руководитель" />
            <ValidationInputField
              v-if="showContrAgent"
              mask="##-########"
              label="Код контрагента"
              v-model="code_contractor"
              validate-name="Код контрагента"
            />
            <div v-if="this.$store.getters.getDealerInfo.type == 'diller'">
              <div class="list__radio-wrap">
                <label class="radio-wrap">
                  <input
                    v-model="diller_vb"
                    type="checkbox"
                    class="radio_none"
                    :value="true"
                  />
                  <div class="radio"></div>
                  <p class="radio_text">
                    Диллер ВБ
                  </p>
                </label>
              </div>
            </div>
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->

        <!--    Информация о договоре    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Информация о договоре</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              v-model="number"
              rules="required"
              label="Номер договора"
            />
            <ValidationFileField
              accept=".docx,.doc,.pdf"
              v-model="file"
              :rules="'required'"
              label="Файл"
            />
          </FormInputBlock>
        </div>
        <!--    Информация о договоре    -->

        <!--    Реквизиты    -->
        <div>
          <FormCardTitle>
            <p class="data__text">Реквизиты</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              rules="required|min:10"
              mask="##########"
              validate-name="ИНН"
              v-model="TIN"
              label="ИНН"
            />
            <ValidationInputField
              :rules="ownership == 'ИП' ? 'required|min:15' : 'required|min:13'"
              :mask="ownership == 'ИП' ? '###############' : '#############'"
              validate-name="ОГРН"
              label="ОГРН"
              v-model="PSRN"
            />
            <ValidationInputField
              rules="required|min:9"
              validate-name="КПП"
              mask="#########"
              v-model="IEC"
              label="КПП"
            />
            <ValidationInputField
              rules="required|min:9|max:9"
              mask="#########"
              validate-name="БИК"
              v-model="RCBIC"
              label="БИК"
            />
            <ValidationInputField
              rules="required"
              v-model="bank"
              validate-name="название банка"
              label="Название банка"
            />
            <ValidationInputField
              rules="required|min:20"
              v-model="CA"
              label="К/с"
              mask="####################"
            />

            <ValidationInputField
              rules="required|min:20"
              v-model="giro"
              label="Р/с"
              mask="####################"
            />
          </FormInputBlock>
        </div>
        <!--    Реквизиты    -->

        <!--    Реквизиты    -->
        <div v-if="getCurrentRole === 70">
          <FormCardTitle>
            <p class="data__text">Информация по платежам</p>
          </FormCardTitle>
          <FormInputBlock>
            <ValidationSelectField
              label="Тип оплаты"
              rules="required"
              v-model="acquiring_entity"
              :items="[
                { text: 'Через Компанию', value: 'self' },
                { text: 'Через Дилера', value: 'dealer' },
                { text: 'Через OPPEN', value: 'oppen' }
              ]"
            />
            <ValidationSelectField
              label="Тип эквайринга"
              rules="required"
              v-model="acquiring_provider"
              :items="[
                { text: 'Не выбран', value: 'no' },
                { text: 'Сбербанк', value: 'sberbank' }
              ]"
            />
            <div
              v-if="
                acquiring_provider === 'sberbank' &&
                  (acquiring_entity === 'self' ||
                    (types === 'diller' && acquiring_entity === 'dealer'))
              "
            />
            <ValidationInputField
              v-model="acquiring_login"
              label="Логин эквайринга"
              rules="required"
              v-if="
                acquiring_provider === 'sberbank' &&
                  (acquiring_entity === 'self' ||
                    (types === 'diller' && acquiring_entity === 'dealer'))
              "
            />

            <ValidationInputField
              type="password"
              v-model="acquiring_password"
              label="Пароль эквайринга"
              rules="required"
              v-if="
                acquiring_provider === 'sberbank' &&
                  (acquiring_entity === 'self' ||
                    (types === 'diller' && acquiring_entity === 'dealer'))
              "
            />
          </FormInputBlock>
        </div>
        <!--    Реквизиты    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editCompany)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import MainLayout from "@/components/layouts/MainLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationSelectField from "@/components/ValidationField/ValidationSelectField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
import { getCitiesTypesClearRequest } from "@/helpers/api/cities";
import { getOrganizationsRequest } from "@/helpers/api/organizations";
import ValidationFileField from "@/components/ValidationField/ValidationFileField";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "EditCompanyPage",
  components: {
    ValidationFileField,
    ValidationAutocompleteField,
    ValidationSelectField,
    ValidationInputField,
    MainLayout,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout
  },
  data() {
    return {
      loading: false,
      showContrAgent: false,
      organizationTypes: Object.entries(
        dictionariesHelper.organizationOwnership
      ).map(([value, text]) => ({ value, text })),
      dillerSearch: async value => {
        return [
          { text: "Oppen", value: 1 },
          ...(
            await getOrganizationsRequest({
              query: { search: value, types: "diller" }
            })
          ).data.results.map(el => ({
            text: el.name,
            value: el.id
          }))
        ];
      },
      citySearch: async value => {
        return (
          await getCitiesTypesClearRequest({
            search: value,
            offset: null
            // type: "organization"
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  computed: {
    organizationType() {
      return this.organizationTypes;
    },
    getCurrentRole: {
      get() {
        return this.$store.getters.getCurrentRole;
      }
    },
    diller: {
      get() {
        this.show();
        return this.$store.getters.getOrganizationEditForm.diller.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "diller",
          value: newValue
        });
      }
    }, //дилер
    code_contractor: {
      get() {
        return this.$store.getters.getOrganizationEditForm.code_contractor
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "code_contractor",
          value: newValue
        });
      }
    },
    ownership: {
      get() {
        return this.$store.getters.getOrganizationEditForm.ownership.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "ownership",
          value: newValue
        });
      }
    }, //"ИП", "ООО" и тд
    full_org_name: {
      get() {
        return this.$store.getters.getOrganizationEditForm.full_org_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "full_org_name",
          value: newValue
        });
      }
    },
    name: {
      get() {
        return this.$store.getters.getOrganizationEditForm.name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "name",
          value: newValue
        });
      }
    },
    number: {
      get() {
        return this.$store.getters.getOrganizationEditForm.number.value;
      },
      set(newVal) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "number",
          value: newVal
        });
      }
    },
    file: {
      get() {
        return this.$store.getters.getOrganizationEditForm.file.value;
      },
      set(newVal) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "file",
          value: newVal
        });
      }
    },
    director: {
      get() {
        return this.$store.getters.getOrganizationEditForm.director.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "director",
          value: newValue
        });
      }
    }, //директор
    types: {
      get() {
        return this.$store.getters.getOrganizationEditForm.type.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "type",
          value: newValue
        });
      }
    },
    TIN: {
      get() {
        return this.$store.getters.getOrganizationEditForm.TIN.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "TIN",
          value: newValue
        });
      }
    }, //инн
    PSRN: {
      get() {
        return this.$store.getters.getOrganizationEditForm.PSRN.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "PSRN",
          value: newValue
        });
      }
    }, //ОГРН
    IEC: {
      get() {
        return this.$store.getters.getOrganizationEditForm.IEC.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "IEC",
          value: newValue
        });
      }
    }, //КПП
    CA: {
      get() {
        return this.$store.getters.getOrganizationEditForm.CA.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "CA",
          value: newValue
        });
      }
    }, //"Корреспондентский счёт"
    giro: {
      get() {
        return this.$store.getters.getOrganizationEditForm.giro.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "giro",
          value: newValue
        });
      }
    }, //Расчётный счёт
    RCBIC: {
      get() {
        return this.$store.getters.getOrganizationEditForm.RCBIC.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "RCBIC",
          value: newValue
        });
      }
    }, //БИК
    bank: {
      get() {
        return this.$store.getters.getOrganizationEditForm.bank.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "bank",
          value: newValue
        });
      }
    }, //Название банка
    address: {
      get() {
        return this.$store.getters.getOrganizationEditForm.address.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "address",
          value: newValue
        });
      }
    }, //Адрес
    phone_number: {
      get() {
        return this.$store.getters.getOrganizationEditForm.phone_number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    email: {
      get() {
        return this.$store.getters.getOrganizationEditForm.email.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "email",
          value: newValue
        });
      }
    },
    city: {
      get() {
        return this.$store.getters.getOrganizationEditForm.city.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "city",
          value: newValue
        });
      }
    }, //id города
    acquiring_provider: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_provider
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_provider",
          value: newValue
        });
      }
    }, //Эквайринг
    actual_address: {
      get() {
        return this.$store.getters.getOrganizationEditForm.actual_address.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "actual_address",
          value: newValue
        });
      }
    },
    diller_vb: {
      get() {
        return this.$store.getters.getOrganizationEditForm.is_vb_diller.value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "is_vb_diller",
          value: newValue
        });
      }
    },
    acquiring_entity: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_entity
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_entity",
          value: newValue
        });
      }
    },
    acquiring_login: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_login
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_login",
          value: newValue
        });
      }
    },
    acquiring_password: {
      get() {
        return this.$store.getters.getOrganizationEditForm.acquiring_password
          .value;
      },
      set(newValue) {
        this.$store.commit("changeEditOrganizationsValue", {
          fieldName: "acquiring_password",
          value: newValue
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearEditCompany");
    next();
  },
  created() {
    if (this.$store.getters.getCurrentRole === 60) {
      this.show();
    }
    this.$store.dispatch("prepareEditCompany", {
      id: this.$route.params.id
    });
  },
  methods: {
    async show() {
      if (this.$store.getters.getOrganizationEditForm.diller.value != 1) {
        await getOrganizationsRequest({
          query: {
            ids:
              this.$store.getters.getOrganizationEditForm.diller.value ||
              this.$store.getters.getCurrentOrganization,
            types: "diller"
          }
        }).then(res => {
          if (res.data.results[0].is_vb_diller) {
            this.showContrAgent = true;
          } else {
            this.showContrAgent = false;
          }
        });
      } else {
        this.showContrAgent = false;
      }
    },
    editCompany() {
      this.loading = true;
      this.$store
        .dispatch("editCompany", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.company)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
